<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <div class="page-wrap pt188">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        修改地址
      </div>
    </top-bar-arrow>
    <div class="main">
      <div class="tips box-shadow">
        <div class="left">
          <img src="../../assets/ty_btn_jingao@2x.png" mode="" />
        </div>
        <div class="right">修改地址会影响物流时效，签发后只能修改一次，若商品已发货、换仓、库存及配送变更等原因，可能会导致修改地址失败，请您谅解。</div>
      </div>
      <div class="address-item box-shadow" @click="editAddress">
        <div class="left">
          <div class="line1">{{ chooseAddress.pcr_address }}</div>
          <div class="line2">{{ chooseAddress.address }}</div>
          <div class="line3">
            <span class="text1">{{ chooseAddress.contacts }}</span>
            <span class="text2">{{ chooseAddress.contact_phone }}</span>
          </div>
        </div>
        <div class="right">
          <van-icon name="arrow" size="18" color="#686677"/>
        </div>
      </div>
    </div>
    <div class="btn-fixed">
      <div class="btn-cancel" @click="back">取消</div>
      <div class="btn-normal" @click="submitData">提交</div>
    </div>
  </div>
</template>

<script>
  import topBarArrow from '../../components/top-bar-arrow'
  import { getOrderInfo, setAddress } from '@/common/api/express.js'
  import { mapMutations, mapGetters } from 'vuex'
  export default {
    components: {
      topBarArrow
    },
    data() {
      return {
        item: '',
      };
    },
    computed: {
      ...mapGetters({
        orderInfo: 'getOrderInfo',
        chooseAddress: 'getChooseAddress'
      })
    },
    mounted() {
    },
    activated () {
    },
    methods: {
      ...mapMutations(['UPDATE_CHOOSE_ADDRESS', 'UPDATE_ORDER_NO']),
      editAddress () {
        this.$router.push({
          name: 'addressChoose'
        })
      },
      submitData () {
        setAddress({
          data: {
            logistics_order_no: this.orderInfo.logistics_order_no,
            address_id: this.chooseAddress.id
          },
          success: res => {
            this.$toast({
              type: 'success',
              message: '提交成功',
              onClose: () => {
                this.$router.go(-1)
              }
            })
          }
        })
      },
      back () {
        this.$router.go(-1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./addressEdit"
</style>

